<template>
  <div class="py-2">
    <div class="px-4" v-if="title">
      <h2>{{ title }}</h2>
    </div>
    <v-toolbar dense elevation="0">
      <slot name="toolbar"></slot>
      <v-sheet class="px-2 mb-2" v-if="showFilter && showFilterText">Filter:
        <template v-for="f in Object.keys(internalFilterData)">
          <v-chip class="mr-1" small color="red" dark :key="f" v-if="internalFilterData[f] != null && internalFilterData[f].length > 0" close @click:close="deleteFilter(f)">{{ f }} : {{ internalFilterData[f] }} </v-chip>
        </template>
      </v-sheet>
      <v-spacer></v-spacer>
      <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" @keyup.enter="getData(true)" @click:append="getData(true)" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>
      <v-btn icon @click="dialog = !dialog" title="Filter" v-if="showFilter">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
      <v-btn icon @click="getData(true)" title="Refresh" small class="mx-1">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
      <v-btn icon @click="exportToCSV()" title="Download" small class="mx-1">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn icon @click="dialog2 = !dialog2" title="Setting" small class="mx-1">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table v-bind="$attrs" v-on="$listeners" v-model="internalValue" :options.sync="internalOptions" :loading="internaLoading" :loading-text="loadingText" :headers="headers" :items="data" :hide-default-header="isMobile" :hide-default-footer="true" :server-items-length="paging.total_results" @page-count="pageCount = $event" dense class="mb-3 my-0">
      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
        <template v-if="$scopedSlots[header.value]">
          <slot :name="header.value" :item="item"></slot>
        </template>
        <template v-else>{{ item[header.value] }} </template>
      </template>

      <template v-for="(slotContent, slotName) in $slots" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps">{{ slotContent }}</slot>
      </template>

      <template v-slot:[`item`]="{ item }" v-if="$scopedSlots['item']">
        <slot name="item" :item="item"></slot>
      </template>
    </v-data-table>
    <div class="px-4 d-flex pt-2">
      <div v-if="!hideItemsPerPageOption">
        <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="internalOptions.itemsPerPage" style="max-width: 100px" hide-details single-line outlined></v-select>
      </div>
      <v-spacer></v-spacer>
      <div class="text-right mx-auto" style="max-width: 600px">
        <v-pagination v-model="internalOptions.page" :length="paging.total_page"></v-pagination>
      </div>
    </div>

    <v-dialog v-model="dialog" :max-width="filterWidth">
      <v-card>
        <v-card-title><v-icon>mdi-filter</v-icon> Filter
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog" icon small title="Close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text @keyup.enter="getData(true), (dialog = !dialog)">
          <slot name="filter-body"></slot>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="getData(true), (dialog = !dialog)" color="primary">Filter</v-btn>
          <v-btn @click="resetFilter(), (dialog = !dialog)">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog" title="Close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="300">
      <v-card>
        <v-card-title><v-icon class="mr-2">mdi-cog</v-icon> Setting
          <v-spacer></v-spacer>
          <v-btn @click="dialog2 = !dialog2" icon small title="Close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text @keyup.enter="getData(true), (dialog2 = !dialog2)">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="internalOptions.itemsPerPage" hide-details outlined></v-select>
          <v-switch v-model="showFilterText" flat label="Show Filter Text" hide-details></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="getData(true), (dialog2 = !dialog2)" color="primary">Apply</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog2 = !dialog2" title="Close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    },
    uri: {
      type: String,
      default: ''
    },
    headers: [],
    filterData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    filterWidth: {
      type: String,
      default: "800",
    },
    hideItemsPerPageOption: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      internalValue: this.value,
      internalLoading: this.loading,
      internalTitle: '',
      loadingText: 'Loading, please wait...',
      search: '',
      internaLoading: this.loading,
      data: [],
      paging: {
        limit: 0,
        page: 0,
        offset: 0,
        total_page: 0,
        total_rows: 0,
        total_results: 0
      },
      internalOptions: {
        itemsPerPage: 20,
      },
      itemsPerPageOption: [5, 10, 20, 50, 100, 200, 500],
      internalFilterData: this.filterData,
      dialog: false,
      dialog2: false,
    };
  },

  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      if (val === true) {
        this.getData(true);
      }
      this.$emit('input', val);
    },
    internalOptions: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    filterData: {
      handler(val) {
        this.internalFilterData = val;
      },
      deep: true,
    },
    internalFilterData: {
      handler(val) {
        this.$emit('filter-data', val);
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    paging: {
      handler(val) {
        this.$emit('paging', val);
      },
      deep: true,
    },
  },
  computed: {
    showFilterText() {
      let a = 0;
      Object.keys(this.internalFilterData).forEach(key => {
        if (this.internalFilterData[key]) {
          a = a + 1;
        }
      });
      if (a == 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    refresh() {
      this.getData(true);
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", this.uri);

      this.internalRefresh = true;
      this.internaLoading = true;

      var formData = this.dataTableFormData(this.internalOptions, this.search, this.internalFilterData);
      this.$axios
        .post(this.uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.data = resData.data.results;
            this.paging = resData.data.paging;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.internaLoading = false;
          this.internalRefresh = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.internaLoading = false;
          this.internalRefresh = false;
        });
    },
    resetFilter() {
      // var resetData = {};
      Object.keys(this.internalFilterData).forEach(key => {
        // const value = this.internalFilterData[key];
        // console.log(`Key: ${key}, Value: ${value}`);
        this.internalFilterData[key] = null;
      });
      // this.internalFilterData.forEach((item) => {
      // });
      // this.internalFilterData = resetData;

      this.refresh();
    },
    deleteFilter(key) {
      this.internalFilterData[key] = null;
      this.getData();
    },
    exportToCSV() {
      const filename = "export.csv";
      const headers = this.headers;
      const results = this.data;

      // Map headers to their corresponding fields in JSON
      const headerMap = headers.map(header => ({ text: header.text, value: header.value }));

      // Create CSV rows
      const csvRows = [];
      // Add header row
      csvRows.push(headerMap.map(header => `"${header.text}"`).join(","));

      // Add data rows
      results.forEach(item => {
        const row = headerMap.map(header => {
          const keys = header.value.split(".");
          let value = item;
          keys.forEach(key => {
            value = value ? value[key] : "";
          });
          return `"${value !== undefined ? String(value).replace(/"/g, '""') : ""}"`;
        });
        csvRows.push(row.join(","));
      });

      // Convert rows to CSV string
      const csvContent = csvRows.join("\n");

      // Create a blob and trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    this.getData(true);
  }
}
</script>