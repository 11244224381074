<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <span>My Tasks</span>
        <v-spacer></v-spacer>
        <v-btn @click="getData(true)" small icon title="Refresh" class="px-0"><v-icon class="mr-1">mdi-refresh</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <div class="text-center py-2" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <v-tabs v-model="tab" flat v-if="!loading">
        <v-tab v-for="(task, i) in tasks" :key="i">
          <div v-if="data.filter((f) => f.proc === task.proc).length >= 1">
            <v-badge right dot color="primary">{{ task.title }}</v-badge>
          </div>

          <div v-if="data.filter((f) => f.proc === task.proc).length == 0">{{ task.title }}</div>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" flat v-if="!loading">
        <v-tab-item v-for="(task, i) in tasks" :key="i" class="mx-2 my-2" style="min-height: 120px;">
          <span class="body-2 text--disabled d-block text-center my-4" v-if="data.filter((f) => f.proc === task.proc).length == 0">No Task Found!</span>
          <v-list dense elevation="2" outlined class="py-0 rounded" shaped two-line>
            <v-list-item dense v-for="item in data.filter((f) => f.proc === task.proc)" :key="item.id" two-line class="py-0">
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs" v-on="on" text title="Update" @click="redirect(task.redirect, { id: item.id })" class="mr-1" large>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Task</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-content class="py-lg-0">
                <v-row>
                  <v-col>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a href="javascript:;" v-bind="attrs" v-on="on" @click="redirect('Administration.CPAR.Detail', { id: item.id })">{{ item.document_no_ }}</a>
                        </template>
                        <span>Click to view detail</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ item.document_type }} | {{ item.area_code }} | {{ item.created_by_username }} | {{ appDateFormat(item.document_date) }}</v-list-item-subtitle>
                  </v-col>
                  <v-col class="d-flex flex-row-reverse">
                    <app-document-status v-model="item.substatus" class="ml-1"></app-document-status>
                    <app-document-status v-model="item.status" class="ml-1"></app-document-status>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>

      <!-- <v-divider class="my-3"></v-divider> -->

      <!-- <v-expansion-panels accordion flat v-if="!loading">
        <v-expansion-panel v-for="(task, i) in tasks" :key="i">
          <v-expansion-panel-header class="my-0">
            <div v-if="data.filter((f) => f.proc === task.proc).length >= 1">
              <v-badge right dot color="primary">{{ task.title }}</v-badge>
            </div>

            <div v-if="data.filter((f) => f.proc === task.proc).length == 0">{{ task.title }}</div>
            <v-spacer></v-spacer>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span class="body-2 text--disabled" v-if="data.filter((f) => f.proc === task.proc).length == 0">No Task Found!</span>
            <v-list dense elevation="1" class="py-0 rounded">
              <v-list-item dense v-for="item in data.filter((f) => f.proc === task.proc)" :key="item.id" two-line class="py-0 px-1">
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <a href="javascript:;" @click="redirect('Administration.CPAR.Detail', { id: item.id })">{{ item.document_no_ }}</a>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.document_type }} | {{ item.area_code }} | {{ item.created_by_username }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon color="primary" text title="Update" @click="redirect(task.redirect, { id: item.id })" class="mr-1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      data: [],
      total: 0,
      tasks: [
        {
          title: "Root Cause Analysis",
          proc: "update-root-cause-analysis",
          redirect: "Administration.CPAR.UpdateCause",
        },
        {
          title: "Correction / Perbaikan",
          proc: "update-correction",
          redirect: "Administration.CPAR.UpdateCorrection",
        },
        {
          title: "Corrective & Preventive Action",
          proc: "update-corrective-preventive-action",
          redirect: "Administration.CPAR.UpdateCorrective",
        },
        // {
        //   title: "Verification",
        //   proc: "update-verification",
        //   redirect: "Administration.CPAR.UpdateVerification",
        // },
      ],
      tab: null,
    };
  },

  methods: {
    async getData(refresh = false) {
      this.loading = true;

      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/your-tasks");

      var config = {
        // params: {
        //   proc: "root-cause-analysis",
        // },
        cacheConfig: !refresh,
      };
      await this.$axios
        .get("cpar/your-tasks", config)
        .then((res) => {
          this.data = res.data.data.results;
          this.total = res.data.data.total;

          this.loading = false;
        })
        .catch((error) => {
          this.data = [];
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
