<template>
  <div>
    <v-sheet class="d-flex flex-row mb-4">
      <v-avatar>
        <v-img :src="resourceUrl(data.user_img)" sizes="36"></v-img>
      </v-avatar>

      <div class="ps-4 d-flex flex-column">
        <div>
          <h6 style="font-size: 14px;"><app-user-info :username="data.created_by_username">@{{ data.created_by_username }}</app-user-info>
            <small class="text--disabled" style="font-size: 12px;font-weight: normal;margin-left: 4px">{{ timeAgo(data.created_date) }}
              <a href="javascript:;" style="color: red" @click="show_edited = !show_edited" v-if="data.edited">(edited)</a>
            </small>
          </h6>
        </div>

        <div class="pb-4" style="min-height: 30px;">
          <div class="mb-2" v-if="data.reply_id > 0" style="max-height: 120px; overflow-y: hidden;">
            <v-sheet class="px-3 rounded mt-2" color="grey lighten-4" outlined style="border-left: 2px solid red !important;">
              <h6 v-if="data.replied_created_by_username" style="font-size: 12px;"><app-user-info :username="data.replied_created_by_username">@{{ data.replied_created_by_username }}</app-user-info>
                <small class="text--disabled" style="font-size: 12px;font-weight: normal;margin-left: 4px">{{ timeAgo(data.replied_created_date) }}</small>
              </h6>
              <comment-body v-model="data.replied_comment_body"></comment-body>
              <span v-if="!data.replied_created_by_username" style="font-size: 12px; font-style: italic;">Comment deleted...</span>
            </v-sheet>
          </div>
          <div style="max-height: 120px; overflow-y: auto;">
            <comment-body v-model="data.comment_body" v-if="!show_edited" ></comment-body>
            <comment-body v-model="data.old_comment_body" v-else :isOld="show_edited"></comment-body>
          </div>
        </div>

        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="grey lighten-1" v-bind="attrs" v-on="on" text x-small icon @click="showDetail()">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="grey lighten-1" v-bind="attrs" v-on="on" text x-small icon @click="replyData()" :loading="loading_delete">
                <v-icon>mdi-reply</v-icon>
              </v-btn>
            </template>
            <span>Reply</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="grey lighten-1" v-bind="attrs" v-on="on" text x-small icon @click="editData()" :loading="loading_delete">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="grey lighten-1" v-bind="attrs" v-on="on" text x-small icon @click="deleteData()" :loading="loading_delete">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </div>
      </div>
    </v-sheet>
    <app-comment-reply :data="data" v-model="dialog_reply" @callback="afterReply"></app-comment-reply>
    <app-comment-detail :data="data" v-model="dialog_detail"></app-comment-detail>
    <app-comment-edit :data="data" v-model="dialog_edit" @callback="afterEdit"></app-comment-edit>
  </div>

  <!-- <v-card elevation="0" outlined shaped class="mb-2" color="grey lighten-4" v-if="(typeof data != 'undefined')">
    <v-card-title class="d-flex py-1" style="line-height: normal;">
      <v-avatar size="36">
        <v-img :src="resourceUrl(data.user_img)" sizes="36"></v-img>
      </v-avatar>
      <div class="px-2">
        <h6 style="font-size: 14px;">{{ data.created_by_username }}</h6>
        <small class="d-block text--disabled" style="font-size: 12px;">{{ data.employee_position }}</small>
        <small class="d-block text--disabled" style="font-size: 12px;">{{ data.comment_datetime }}</small>
      </div>
      <v-spacer></v-spacer>
      <div class="float-right" style="font-size: 12px;font-weight: normal;">{{ data.created_date }}</div>
    </v-card-title>
    <v-card-text class="py-0" style="max-height: 100px;overflow-y: hidden;">
      <pre class="ipre" style="white-space: pre-wrap;" v-if="!show_edited">{{ data.comment_body }}</pre>
      <pre class="ipre" style="white-space: pre-wrap;" v-else>{{ data.old_comment_body }}</pre>
    </v-card-text>
    <v-card-actions class="pt-0">
      <a href="javascript:;" style="color: red" @click="show_edited = !show_edited" v-if="data.edited">edited</a>
      <v-spacer></v-spacer>
      <v-btn text small icon @click="editData()" :loading="loading_delete">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn text small icon @click="deleteData()" :loading="loading_delete">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <CommentDetail :data="data"></CommentDetail>
      <app-comment-edit :data="data" v-model="dialog_edit" @callback="afterEdit"></app-comment-edit>
    </v-card-actions>
  </v-card> -->
</template>

<script>
import AppUserInfo from '../AppUserInfo.vue';
import CommentBody from './CommentBody.vue';
export default {
  components: { AppUserInfo, CommentBody },
  props: ["data"],
  data() {
    return {
      loading_delete: false,
      dialog_detail: false,
      dialog_reply: false,
      dialog_edit: false,
      show_edited: false,
    }
  },
  methods: {
    showDetail() {
      this.dialog_detail = true
    },
    editData() {
      this.dialog_edit = true
    },
    replyData() {
      this.dialog_reply = true
    },
    afterReply() {
      this.$emit("after-reply");
    },
    afterEdit() {
      this.$emit("after-edit");
    },
    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("comments/delete/" + this.data.id)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.$emit("after-delete");
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete document?", post);
    },
  }
}
</script>

<style scoped>
.ipre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.ipre_reply {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
</style>