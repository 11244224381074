<template>
  <div>
    <v-card>
      <app-data-table
        ref="refAppDataTable"
        :uri="datatable.uri"
        :headers="datatable.headers"
        title="List Item">
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      datatable: {
        uri: 'item/data',
        headers: [
          { text: "No.", value: "no_" },
          { text: "Description", value: "description" },
          { text: "Search Description", value: "search_description" },
          { text: "Base UoM", value: "base_unit_of_measure" },
        ],
      },
    };
  },
};
</script>