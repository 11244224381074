<template>
  <v-dialog width="500" v-model="internalValue" ref="refParent">
    <v-card>
      <v-card-title>Write Comment</v-card-title>

      <v-card-text>
        <v-textarea v-model="comment_body" ref="refCommentBody" outlined class="fill-height" hide-details @input="onInput"></v-textarea>

        <!-- Menu untuk daftar username -->
        <v-menu v-model="showList" :close-on-content-click="false" :offset-y="false" :nudge-bottom="5" transition="scale-transition" :position-x="menuPosition.x" :position-y="menuPosition.y" absolute>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on"></div>
          </template>

          <v-list dense>
            <v-list-item v-for="(user, index) in filteredUsers" :key="index" @click="addMention(user)" class="py-0" style="min-height: 25px;">
              <v-list-item-title class="py-0">@{{ user }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <p class="mt-2 mb-2 text-caption">Type @ in front of the name of the person you want to mention, then select that person from the menu that appears.</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="saveComment()">Submit</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="internalValue = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    document_id: {
      type: Number,
      default: 0,
    },
    source_document: {
      type: String,
      default: "",
    },
    document_no_: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: null,
      internalValue: this.value,
      comment_body: "",
      users: [], // Daftar username
      filteredUsers: [], // Hasil filter berdasarkan input
      showList: false, // Kontrol untuk menu
      menuPosition: { x: 0, y: 0 }, // Posisi menu
    }
  },

  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);

      if (val == false) {
        this.$emit('callback');
      }
    },
  },

  methods: {
    close() {
      this.internalValue = false;
    },

    async saveComment() {
      if (this.comment_body.toString().trim().length === 0) return alert('Can\'t empty!');
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("document_id", this.document_id);
      formData.append("source_document", this.source_document);
      formData.append("document_no_", this.document_no_);
      formData.append("comment_body", this.comment_body);
      await this.$axios.post("comments/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
          this.comment_body = ""

          this.close();
        })
        .catch((error) => {
          this.loading = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.close();

        });
    },
    onInput() {
      const textarea = this.$refs.refCommentBody.$el.querySelector('textarea');
      // Cari posisi '@' terakhir
      const mentionIndex = this.comment_body.lastIndexOf('@');
      if (mentionIndex !== -1) {
        // Ambil substring setelah '@'
        const search = this.comment_body.slice(mentionIndex + 1).trim();
        if (search.length == 0) {
          this.showList = false; // Sembunyikan menu jika '@' dihapus
          return;
        }

        // Filter daftar username
        // this.filteredUsers = this.users.filter((user) =>
        //   user.toLowerCase().startsWith(search.toLowerCase())
        // ).slice(0, 10);
        // this.findUser(search);

        this.filteredUsers = [];
        const config = {
          params: {
            search: search
          }
        }
        this.$axios.get('comments/users', config).then((res) => {
          if (res.data.data.length > 0) {
            this.filteredUsers = res.data.data;
          }

          // Jika ada hasil, tampilkan menu dan hitung posisinya
          if (this.filteredUsers.length > 0) {
            this.showList = true;

            // Hitung posisi kursor
            const coordinates = this.getCaretCoordinates(textarea, mentionIndex + 1);
            const textareaRect = textarea.getBoundingClientRect();

            // Perhatikan posisi relatif ke dialog
            const dialogRect = this.$refs.refParent.$el.getBoundingClientRect();


            // Set posisi menu relatif terhadap textarea
            this.menuPosition.x = textareaRect.left - dialogRect.left + coordinates.left;
            this.menuPosition.y = textareaRect.top - dialogRect.top + coordinates.top + 15;

          } else {
            this.showList = false;
          }
        }).catch((error) => {
          console.log(error);
        })
      } else {
        this.showList = false; // Sembunyikan menu jika '@' dihapus
      }

      console.log(this.menuPosition);

    },
    addMention(user) {
      // Tambahkan username ke dalam input
      const mentionIndex = this.comment_body.lastIndexOf('@');
      if (mentionIndex !== -1) {
        this.comment_body =
          this.comment_body.slice(0, mentionIndex) + '@' + user + ' '; // Ganti '@' dengan username
        this.showList = false; // Sembunyikan menu
      }
    },
    getCaretCoordinates(element, position) {
      // Helper untuk menghitung posisi kursor di textarea
      const div = document.createElement('div');
      const style = window.getComputedStyle(element);

      [...style].forEach((prop) => {
        div.style[prop] = style[prop];
      });

      div.style.position = 'absolute';
      div.style.visibility = 'hidden';
      div.style.whiteSpace = 'pre-wrap';
      div.style.wordWrap = 'break-word';

      const span = document.createElement('span');
      span.textContent = element.value.substring(0, position);
      div.appendChild(span);

      document.body.appendChild(div);

      const coordinates = {
        left: span.offsetWidth,
        top: span.offsetHeight,
      };

      document.body.removeChild(div);
      return coordinates;
    },
    async findUser(search = "") {
      this.filteredUsers = [];
      const config = {
        params: {
          search: search
        }
      }
      await this.$axios.get('comments/users', config).then((res) => {
        if (res.data.data.length > 0) {
          this.filteredUsers = res.data.data;
        }
      }).catch((error) => {
        console.log(error);
      })
    }
  },

  mounted() {
    this.comment_body = ""
  }
}
</script>