<template>
  <div>
    <div class="ipre" style="white-space: pre-wrap;" v-html="maskedValue"></div><span class="text--disabled" v-if="isOld" style="font-size: small;font-style: italic;"> - old</span>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    isOld: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      internalValue: this.value,
    }
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);
    },
  },
  computed: {
    maskedValue() {
      let maskedValue = [];
      let splitVal = this.internalValue.toString().split(" ");
      splitVal.forEach((_word) => {
        if (_word.charAt(0) == "@") {
          maskedValue.push(this.maskedMention(_word));
        } else {
          maskedValue.push(_word)
        }
      });
      return maskedValue.join(" ");
    },
  },
  methods: {
    maskedMention(val) {
      if (val !== "") return '<b>' + val + '</b>'
      return;
    }
  }
}
</script>

<style scoped>
.ipre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.ipre_reply {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
</style>