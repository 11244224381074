<template>
  <div>
    <v-card flat elevation="0">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template v-slot:is_active="{ item }">
          <v-btn icon text x-small class="mx-1" color="green" @click="setActive(item, false)" v-if="item.is_active"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon text x-small class="mx-1" color="primary" @click="setActive(item, true)" v-else><v-icon>mdi-cancel</v-icon></v-btn>
        </template>
        <template v-slot:action="{ item }">
          <v-btn icon text x-small class="mx-1" color="primary" @click="editData(item.id)"><v-icon>mdi-pencil</v-icon></v-btn>
        </template>
      </app-data-table>
    </v-card>

    <ModuleEditUser ref="refModuleEditUser"></ModuleEditUser>
  </div>
</template>

<script>
import ModuleEditUser from './ModuleEditUser.vue';
export default {
  components: {
    ModuleEditUser,
  },
  props: {},
  data() {
    return {
      datatable: {
        uri: 'module/data',
        headers: [
          { text: "ID", value: "id", class: "text-no-wrap" },
          { text: "Name", value: "name", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Is Active", value: "is_active", class: "text-no-wrap" },
          { text: "#", value: "action", name: "action", class: "text-no-wrap", searchable: false, sortable: false },
        ],
      },

      accessType: null,
    };
  },

  methods: {
    editData(moduleId) {
      this.redirect("Setting.General.ModuleEdit", { id: moduleId });
    }

  },

  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
tr .show-on-hover button {
  display: none;
}

tr:hover .show-on-hover button {
  display: block;
}
</style>
