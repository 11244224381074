<template>
  <div>
    <v-card flat elevation="0" class="">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template v-slot:id="{ item }" v-if="!isMobile">
          <div class="d-inline-block">
            <app-tooltip text="Edit">
              <v-btn small color="primary" @click="redirect('HumanResource.Department.Edit', { id: item.id })" icon><v-icon>mdi-pencil</v-icon></v-btn>
            </app-tooltip>
          </div>
          <div class="d-inline-block">
            <app-tooltip text="Delete">
              <v-btn small color="primary" @click="deleteData(item)" icon><v-icon>mdi-delete</v-icon></v-btn>
            </app-tooltip>
          </div>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: null,

      datatable: {
        uri: "department/data",
        headers: [
          { text: "#", value: "id" },
          { text: "Department Code", value: "dept_code" },
          { text: "Department Name", value: "dept_name" },
        ],
      },
    };
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
    async deleteData(item) {
      const post = async () => {
        await this.$axios
          .delete("department/delete/" + item.id)
          .then((res) => {
            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);
            }
            this.refreshData();
            this.datatable.loading = false;
          })
          .catch((error) => {
            this.axiosErrorHandler(error);
            this.datatable.loading = false;
          });
      }

      this.showConfirm("Confirm Delete", "Delete \"" + item.dept_code + "\"?", post)
    },
  },
};
</script>
