<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-card-title>List Asset</v-card-title>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" show-filter :filter-data="filterForm">
        <template #filter-body>
          <v-autocomplete v-model="filterForm.fa_subclass_code" label="FA Subclass Code" :items="filterData.fa_subclass_code" class="mb-2" hide-details multiple deletable-chips chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.area_code" label="Area Code" :items="filterData.area_code" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.fa_location_code" label="FA Location Code" :items="filterData.fa_location_code" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.responsibility" label="Responsibility" :items="filterData.responsibility" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: null,

      datatable: {
        uri: "mjo/data-asset",
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Serial No.", value: "serial_no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Description 2", value: "description2", class: "text-no-wrap" },
          { text: "FA Subclass Code", value: "fa_subclass_code", class: "text-no-wrap" },
          { text: "Area Code", value: "area_code", class: "text-center text-no-wrap" },
          { text: "Location Code", value: "location_code	", class: "text-no-wrap" },
          { text: "FA Location Code", value: "fa_location_code	", class: "text-no-wrap" },
          { text: "Responsibility", value: "responsibility", class: "text-no-wrap" },
        ],
      },
      filterData: {
        fa_subclass_code: [],
        area_code: [],
        fa_location_code: [],
        responsibility: [],
      },
      filterForm: {
        fa_subclass_code: [],
        area_code: [],
        fa_location_code: [],
        responsibility: [],
      }
    };
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
    async getFilterValuesAsset(refresh = false) {
      this.loading = true;

      this.mjo_header = {};
      this.mjo_approval = [];
      this.mjo_comment = [];
      this.user = {};

      const uri = "mjo/filter-values-asset";
      if (refresh == true) this.AxiosStorageRemove("GET", uri);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get(uri, config)
        .then((res) => {
          this.filterData.fa_subclass_code = res.data.data.fa_subclass_code;
          this.filterData.area_code = res.data.data.area_code;
          this.filterData.fa_location_code = res.data.data.fa_location_code;
          this.filterData.responsibility = res.data.data.responsibility;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.getFilterValuesAsset();
  }
};
</script>
