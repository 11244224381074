<template>
  <div>
    <v-card>
      <app-data-table ref="refAppDataTable" uri="bincontent/data" :headers="headers" :filter-data="filterData" show-filter>
        <template v-slot:location_code="{ item }">
          <a href="javascript:;" @click="filterData.location_code = item.location_code; $refs.refAppDataTable.refresh()">{{ item.location_code }}</a>
        </template>
        <template v-slot:bin_code="{ item }">
          <a href="javascript:;" @click="filterData.bin_code = item.bin_code; $refs.refAppDataTable.refresh()">{{ item.bin_code }}</a>
        </template>
        <template v-slot:item_no_="{ item }">
          <a href="javascript:;" @click="filterData.item_no_ = item.item_no_; $refs.refAppDataTable.refresh()">{{ item.item_no_ }}</a>
        </template>
        <template #filter-body>
          <v-text-field v-model="filterData.location_code" dense hide-details outlined flat class="mb-2" label="Location Code"></v-text-field>
          <v-text-field v-model="filterData.bin_code" dense hide-details outlined flat class="mb-2" label="Bin Code"></v-text-field>
          <v-text-field v-model="filterData.item_no_" dense hide-details outlined flat class="mb-2" label="Item No"></v-text-field>
          <v-text-field v-model="filterData.quantity_base" dense hide-details outlined flat class="mb-2" label="Quantity"></v-text-field>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filterData: {
        location_code: "",
        bin_code: "",
        item_no_: "",
        quantity_base: "",
      },

      headers: [
        { text: "Location Code", value: "location_code" },
        { text: "Bin Code", value: "bin_code" },
        { text: "Item No.", value: "item_no_" },
        { text: "Item Description", value: "item_description" },
        { text: "UoM Code", value: "unit_of_measure_code" },
        { text: "Quantity Base", value: "quantity_base", cellClass: "text-end" },
        { text: "Qty. per UoM", value: "qty_per_unit_of_measure", cellClass: "text-end" },
      ],
    };
  },

  mounted() {
    // this.getData();
  },
};
</script>
