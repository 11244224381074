<template>
  <div>
    <v-card flat elevation="0" class="">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template v-slot:no_="{ item }" v-if="!isMobile">
          <detail-dialog :id="parseInt(item.id)" @callback="getData(true)">
            <template v-slot:activator="{ on }">
              <a href="javascript:;" v-on:click="on" style="text-wrap: nowrap;">{{ item.no_ }}</a>
            </template>
          </detail-dialog>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
import DetailDialog from "./DetailDialog.vue";
export default {
  components: { DetailDialog },
  data() {
    return {
      loading: null,
      datatable: {
        uri: 'contact/data',
        headers: [
          { text: "No.", value: "no_" },
          { text: "First Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Gender", value: "gender" },
          { text: "Company", value: "company_name" },
          { text: "Job Title", value: "job_title" },
          { text: "Phone", value: "phone_no" },
          { text: "Mobile", value: "mobile_phone_no" },
          { text: "Email", value: "email" },
        ],
      },
    };
  },
  mounted() {
  },
};
</script>
