<template>
  <div>
    <v-container>
      <page-title title="Sales Price"></page-title>

      <v-card class="mb-2">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab v-for="item in items" :key="item.tab">{{ item.tab }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <component :is="item.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
export default {
  components: {
    ListData,
  },
  data() {
    return {
      dialog: null,
      search: "",

      loading2: false,
      search2: "",
      campaignList: [],
      attrs: {
        boilerplate: true,
        elevation: 0,
      },
      selectedCampaign: null,
      benched: 0,

      tab: null,
      items: [{ tab: "Sales Price", component: "ListData" }],
    };
  },

  computed: {},

  methods: {},

  mounted() {
    this.requiredLogin();

    this.modulePermission("salesprice", "view", true);
  },
};
</script>
