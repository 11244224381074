<template>
  <v-card outlined class="mb-1" :loading="loading">
    <v-form v-model="valid" lazy-validation ref="form1">
      <v-toolbar elevation="0">
        <v-toolbar-title>Module Permission</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="primary" icon @click="getMenu(true)">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col>
            <div class="d-flex flex-row">
              <div style="max-width: 300px">
                <v-autocomplete outlined hide-details dense :items="userGroupList" item-text="name" item-value="id" label="User Group" append-outer-icon="mdi-content-copy" persistent-hint hint="Copy from User Group" v-model="selectedUserGroup" @click:append-outer="copyFromUserGroup()"></v-autocomplete>
              </div>
              <v-spacer></v-spacer>
              <v-btn @click="clearPermission()" text color="red"><v-icon>mdi-close</v-icon> Clear Permission</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text style="max-height: 1000px; overflow-y: auto">
        <!-- <v-sheet v-for="(item, i) in userModule" :key="i" elevation="1" outlined class="pa-4">
          <div class="d-flex justify-end mb-3">
            <h4>{{ item.description }}</h4>
            <v-spacer></v-spacer>
            <v-btn x-small color="primary" class="mx-1" v-if="item.perm.length > 0" @click="selectAll(item.perm)">Select All</v-btn>
            <v-btn x-small color="light" class="mx-1" v-if="item.perm.length > 0" @click="diselectAll(item.perm)">Diselect All</v-btn>
          </div>

          <template v-for="perm in item.perm">
            <v-checkbox @change="updateModule(perm)" v-model="perm.enable" :input-value="perm.enable" :label="perm.perm_name" :key="perm.id" hide-details class="my-0 hoverme"></v-checkbox>
          </template>
</v-sheet> -->

        <card-expansion v-for="(item, i) in userModule" :title="item.id + ' - ' + item.description" is-minimize :key="i" class="mb-2">
          <template slot="action">
            <v-btn x-small text color="primary" class="mx-1" v-if="item.perm.length > 0" @click="selectAll(item.perm)">Select All</v-btn>
            <v-btn x-small text color="light" class="mx-1" v-if="item.perm.length > 0" @click="diselectAll(item.perm)">Diselect All</v-btn>
          </template>
          <v-list flat class="py-0 my-0">
            <v-list-item v-for="perm in item.perm" :key="perm.id" :value="perm.enable" class="py-0 my-0" style="min-height: 0px;">
              <v-checkbox @change="updateModule(perm)" v-model="perm.enable" :input-value="perm.enable" :label="perm.perm_name" :key="perm.id" hide-details class="my-0 hoverme"></v-checkbox>
            </v-list-item>
          </v-list>
          <!-- <v-checkbox @change="updateModule(perm)" v-model="perm.enable" :input-value="perm.enable" :label="perm.perm_name" :key="perm.id" hide-details class="my-0 hoverme"></v-checkbox> -->
        </card-expansion>

        <!-- <v-list dense>
          <template v-for="(item, i) in userModule">
            <v-list-group :key="i">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.description"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item-group multiple no-action :value="true">
                <v-list-item :key="perm.id">
                  <template>
                    <v-list-item-action class="pl-6">
                      <v-checkbox :input-value="perm.enable"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>
          </template>
        </v-list>-->
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    username: String,
  },
  data() {
    return {
      valid: null,
      loading: null,
      editForm: false,
      selected: {},
      userModule: [],
      userGroupList: [],
      selectedUserGroup: 0,
    };
  },
  methods: {
    async getMenu(refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "user/get-module");
      this.loading = true;
      var params = {
        username: this.username,
      };
      await this.$axios
        .get("user/get-module", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.userModule = resData.data.user_modules;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async updateModule(perm) {
      this.loading = true;
      var formData = new FormData();
      console.log(perm);
      formData.append("username", this.username);
      formData.append("module_perm_id", perm.id);
      formData.append("module_id", perm.module_id);
      formData.append("perm_name", perm.perm_name);
      formData.append("enable", perm.enable);
      await this.$axios
        .post("user/update-module", formData)
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.getMenu(true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async selectAll(perms) {
      // eslint-disable-next-line no-unused-vars
      perms.forEach((item, index) => {
        item.enable = true;
      });

      this.loading = true;
      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("perms", JSON.stringify(perms));
      await this.$axios
        .post("user/update-module-multiple", formData)
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.getMenu(true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async diselectAll(perms) {
      // eslint-disable-next-line no-unused-vars
      perms.forEach((item, index) => {
        item.enable = false;
      });

      this.loading = true;
      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("perms", JSON.stringify(perms));
      await this.$axios
        .post("user/update-module-multiple", formData)
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.getMenu(true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async getUserGroup(refresh) {
      this.userGroupList = [];

      if (refresh) this.AxiosStorageRemove("GET", "user/usergroup-list");

      await this.$axios
        .get("user/usergroup-list", {
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.userGroupList = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
        });
    },
    clearPermission() {
      let post = () => {
        this.showLoadingOverlay(true);
        let data = {
          username: this.username,
        }
        return this.$axios
          .post("user/clear-permission", this.objectToFormData(data))
          .then((res) => {
            var resData = res.data;
            this.showAlert(resData.status, resData.message);
            this.showLoadingOverlay(false);
            this.getMenu(true);
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
            this.getMenu(true);
          });
      }

      this.showConfirm("Confirm", "Clear Permission?", post);
    },
    copyFromUserGroup() {
      let post = () => {
        if (this.selectedUserGroup === null || this.selectedUserGroup == 0){
          this.showAlert("error", "Select User Group");
          return;
        }
        
        this.showLoadingOverlay(true);
        let data = {
          username: this.username,
          user_group_id: this.selectedUserGroup,
        }
        return this.$axios
          .post("user/copy-permission-from-usergroup", this.objectToFormData(data))
          .then((res) => {
            var resData = res.data;
            this.showAlert(resData.status, resData.message);
            this.selectedUserGroup = 0;
            this.showLoadingOverlay(false);
            this.getMenu(true);
          })
          .catch((error) => {
            this.selectedUserGroup = 0;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
            this.getMenu(true);
          });
      }

      this.showConfirm("Confirm", "Copy from User Group?", post);
    },
  },
  mounted() {
    this.getMenu();

    this.getUserGroup();
  },
};
</script>


<style scoped>
.hoverme:hover {
  font-weight: bold;
}
</style>