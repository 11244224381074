<template>
  <div>
    <v-card flat elevation="0">
      <v-card-title>
        <span>User Group</span>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" color="primary" @click="dialogAdd = true, addFormData.name = ''">Add</v-btn>
      </v-card-title>
      <app-dialog v-model="dialogAdd" :title="dialogDetailData.name" max-width="600px">
        <template #toolbar>

        </template>

        <v-card>
          <v-text-field dense hide-details outlined class="mb-2" label="Name" v-model="addFormData.name" @keyup.enter="saveData()" :disabled="loadingAdd"></v-text-field>
        </v-card>

        <template #actions>
          <v-btn color="primary" @click="saveData()" :loading="loadingAdd">Save</v-btn>
        </template>
      </app-dialog>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template #toolbar>
          <v-btn @click="deleteData()" :disabled="selectedRows.length == 0" text icon small class="ml-0 mr-2">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn @click="selectedRows = []" :disabled="selectedRows.length == 0" text icon small class="mr-2">
            <v-icon>mdi-format-list-checks</v-icon>
          </v-btn>
        </template>
        <template v-slot:id="{ item }">
          <v-checkbox :value="item.id" v-model="selectedRows" hide-details dense class="my-0 py-0"></v-checkbox>
        </template>
        <template v-slot:name="{ item }">
          <a href="javascript:;" @click="showDetail(item)">{{ item.name }}</a>
        </template>
      </app-data-table>
    </v-card>

    <app-dialog v-model="dialogDetail" :title="dialogDetailData.name" max-width="800px">
      <template #toolbar>
        <app-button @click="getModulePermsData(true)" title="Refresh" mdi-icon="mdi-sync" :loading="datatable2.loading"></app-button>
        <app-button @click="deleteModulePermsData()" :disabled="selectedModulePermsData.length == 0" title="Delete Selected" mdi-icon="mdi-delete" :loading="loadingDeleteModulePerms"></app-button>
        <app-button @click="selectedModulePermsData = []" :disabled="selectedModulePermsData.length == 0" title="Clear Selected" mdi-icon="mdi-format-list-checks" :loading="loadingDeleteModulePerms"></app-button>
      </template>
      <div style="min-height: 360px;">
        <v-row>
          <v-col>
            <v-card class="mb-2">
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="10">
                    <v-autocomplete class="mb-1" label="Module" outlined dense hide-details v-model="selectedModulePerm" :items="listModulePerm" item-value="id" item-text="text" multiple chips small-chips deletable-chips hide-no-data hide-selected>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-btn @click="saveModulePermsData()" :loading="loadingAddModulePerms" :block="isMobile">Add</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <card-expansion title="Permission" no-padding>
              <template #action>
                <v-text-field outlined dense hide-details v-model="search2" style="max-width: 220px;" rounded placeholder="Search" @keyup.enter="getModulePermsData(true)" clear-icon clearable></v-text-field>
                <v-btn icon text @click="getModulePermsData(true)" small :loading="datatable2.loading">
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              <v-data-table @page-count="pageCount2 = $event" :options.sync="datatable_options2" :server-items-length="datatable2.totalRows" :items-per-page="datatable_options2.itemsPerPage" :loading="datatable2.loading" :headers="datatable2.columns" :items="datatable2.data" :search="search2" dense class="mb-3 my-0" hide-default-footer multi-sort>
                <template v-slot:[`item.id`]="{ item }">
                  <v-checkbox :value="item.id" v-model="selectedModulePermsData" hide-details dense class="my-0 py-0"></v-checkbox>
                </template>
              </v-data-table>
              <div class="text-right pt-2 mx-auto" style="max-width: 600px">
                <v-pagination v-model="datatable_options2.page" :length="totalPage2"></v-pagination>
              </div>
            </card-expansion>
          </v-col>
        </v-row>
      </div>
    </app-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: null,
      search2: "",
      datatable: {
        uri: 'usergroup/data',
        headers: [
          { text: "#", value: "id", class: "text-no-wrap", },
          { text: "Name", value: "name", class: "text-no-wrap col-12" },
        ],
      },
      selectedRows: [],
      datatable_options2: {
        itemsPerPage: 10
      },
      itemsPerPageOption2: [10, 20, 50, 100, 500],
      totalPage2: 0,
      datatable2: {
        search: null,
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "#", value: "id", class: "text-no-wrap maxw", },
          { text: "Module Name", value: "module_name", class: "text-no-wrap col-6" },
          { text: "Perm Name", value: "perm_name", class: "text-no-wrap col-6" },
          { text: "Perm ID", value: "module_perm_id", class: "text-no-wrap col-6" },
        ],
        data: [],
      },
      dialogDetail: false,
      dialogDetailData: {},

      listModulePerm: [],
      selectedModulePerm: [],

      loadingAddModulePerms: false,

      selectedModulePermsData: [],
      loadingDeleteModulePerms: false,

      dialogAdd: false,
      loadingAdd: false,
      addFormData: {
        name: "",
      },
      loadingDelete: false
    };
  },

  watch: {
    datatable_options2: {
      handler() {
        this.getModulePermsData();
      },
      deep: true,
    },

    search2: {
      handler() {
        this.datatable_options2.page = 1;
        this.getModulePermsData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
    getData(refresh) {
      const uri = "usergroup/data";
      if (refresh) this.AxiosStorageRemove("POST", uri);
      this.datatable.loading = true;
      this.$axios
        .post(uri, this.dataTableFormData(this.datatable_options))
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    showDetail(item) {
      this.dialogDetailData = item;

      this.dialogDetail = true;

      this.getModulePermsData(true);
    },
    hideDetail() {
      this.dialogDetail = false;
    },
    getPerms() {
      const uri = "usergroup/perms";
      this.listModulePerm = []
      const params = {
        user_group_id: this.dialogDetailData.id
      }
      this.$axios
        .get(uri, {
          params: params
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.listModulePerm = resData.data.perms;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
        });
    },
    getModulePermsData(refresh) {
      const uri = "usergroup/data-perms";
      if (refresh) this.AxiosStorageRemove("POST", uri);
      this.datatable2.loading = true;
      this.$axios
        .post(uri, this.dataTableFormData(this.datatable_options2, this.search2, { user_group_id : this.dialogDetailData.id }))
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable2.data = resData.data.results;
            this.datatable2.totalRows = resData.data.paging.total_rows;
            this.totalPage2 = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable2.loading = false;

          this.getPerms();
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable2.loading = false;
        });
    },
    saveModulePermsData() {
      const uri = "usergroup/save-module-perms";
      let postData = {
        user_group_id: this.dialogDetailData.id,
        module_perm_id: this.selectedModulePerm,
      }
      this.loadingAddModulePerms = true;
      this.$axios
        .post(uri, this.objectToFormData(postData))
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.getModulePermsData();
          this.loadingAddModulePerms = false;
          this.selectedModulePerm = [];
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.getModulePermsData();
          this.loadingAddModulePerms = false;
        });
    },
    deleteModulePermsData() {
      let post = () => {
        const uri = "usergroup/delete-module-perms";
        let postData = {
          id: this.selectedModulePermsData,
        }
        this.loadingDeleteModulePerms = true;
        this.$axios
          .post(uri, this.objectToFormData(postData))
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getModulePermsData();
            this.loadingDeleteModulePerms = false;
            this.selectedModulePermsData = [];
          })
          .catch((error) => {
            this.axiosErrorHandler(error);
            this.getModulePermsData();
            this.loadingDeleteModulePerms = false;
          });
      }
      this.showConfirm("Confirm", "Delete Selected?", post);
    },
    saveData() {
      const uri = "usergroup/save";
      let postData = {
        name: this.addFormData.name,
      }
      this.loadingAdd = true;
      this.$axios
        .post(uri, this.objectToFormData(postData))
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.getModulePermsData();
          this.loadingAdd = false;
          this.dialogAdd = false;
          this.refreshData();
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.getModulePermsData();
          this.loadingAdd = false;
          this.dialogAdd = false;
          this.refreshData();
        });
    },
    deleteData() {
      let post = () => {
        const uri = "usergroup/delete";
        let postData = {
          id: this.selectedRows,
        }
        this.loadingDelete = true;
        this.$axios
          .post(uri, this.objectToFormData(postData))
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.getModulePermsData();
            this.loadingDelete = false;
            this.refreshData();
          })
          .catch((error) => {
            this.axiosErrorHandler(error);
            this.getModulePermsData();
            this.loadingDelete = false;
            this.refreshData();
          });
      }
      this.showConfirm("Confirm", "Delete Selected?", post);
    },
  },

  mounted() {
    // this.getData();

    // this.getPerms();
  },
};
</script>

<style scoped>
.maxw {
  max-width: 100px !important;
}
</style>