<template>
  <div>
    <v-card flat elevation="1" outlined>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template v-slot:action="{ item }">
          <v-btn icon text x-small class="mx-1" color="primary" @click="editData(item)"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn icon text x-small class="mx-1" color="primary" @click="deleteData(item)"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data() {
    return {
      loading: null,
      datatable: {
        uri: 'cparcategory/data',
        headers: [
          { text: "Code", value: "code", class: "text-no-wrap" },
          { text: "Name", value: "name", class: "text-no-wrap" },
          { text: "#", value: "action", name: "action", class: "text-no-wrap", searchable: false, sortable: false },
        ],
      },
    };
  },

  methods: {
    refreshData(){
      this.$refs.refAppDataTable.refresh();
    },
    editData(item) {
      this.selectedItem = item;
      this.redirect("Setting.CPARCategory.Edit", { id: item.id });
    },
    deleteData(item) {
      this.selectedItem = item;

      const post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        var config = {
          params: {
            id: item.id,
          },
          cacheConfig: false,
        };
        await this.$axios
          .delete("cparcategory/delete", config)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshData();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Delete Data?", post);
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
tr .show-on-hover button {
  display: none;
}
tr:hover .show-on-hover button {
  display: block;
}
</style>
