var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","elevation":"0"}},[_c('app-data-table',{ref:"refAppDataTable",attrs:{"uri":_vm.datatable.uri,"headers":_vm.datatable.headers},scopedSlots:_vm._u([{key:"no_",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.getCustomers(item.no_)}}},[_vm._v(_vm._s(item.no_))])]}},{key:"starting_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.appDateFormat(item.starting_date)))])]}},{key:"ending_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.appDateFormat(item.ending_date)))])]}},{key:"last_sync_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.appDatetimeFormat(item.last_sync_date)))])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"420"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"max-width":"420","loading":_vm.loading2}},[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',[_vm._v("Select Customer")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-select',{attrs:{"clearable":"","hide-selected":"","items":_vm.customersListItems,"item-text":"text","item-value":"value","hide-details":"","label":"Select Customer"},model:{value:(_vm.selectedCutomerNo),callback:function ($$v) {_vm.selectedCutomerNo=$$v},expression:"selectedCutomerNo"}}),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.getCustomers(true)}}},[_vm._v("Reload")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":_vm.disabledGotoDetail},on:{"click":function($event){return _vm.getDetail()}}},[_vm._v("Go to detail")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }