<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>Berita Acara</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" dense class="mb-3 my-0">
        <template v-slot:[`item.document_no_`]="{ item }" v-if="!isMobile">
          <a href="javascript:;" @click="redirect('Accounting.BeritaAcara.Detail', { id: item.id })">{{ item.document_no_ }}</a>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">{{ item.document_no_ }}</v-card-title>
            <v-card-subtitle class="my-0 py-0">
              {{ item.document_type }}<br>
              {{ item.area_code }}
            </v-card-subtitle>
            <v-card-actions class="d-flex align-center flex-row mx-2">
              <v-btn @click="redirect('Accounting.BeritaAcara.Edit', { id: item.id })" outlined rounded color="primary">Edit</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="redirect('Accounting.BeritaAcara.Detail', { id: item.id })" outlined rounded color="primary">Detail</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import DetailDialog from "../../accounting/beritaacara/components/DetailDialog.vue";
export default {
  components: { },
  props: {
    status: String,
  },
  data() {
    return {
      loading: null,
      search: "",
      datatable_options: {},
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "No.", value: "document_no_" },
          { text: "Document Date", value: "document_date" },
          { text: "Source Document", value: "source_document" },
          { text: "Document Type", value: "document_type" },
          { text: "Area Code", value: "area_code" },
          { text: "Description", value: "description" },
          { text: "Target Date", value: "target_date" },
          { text: "Status", value: "status" },
          { text: "Created By", value: "created_by_username", sortable: false },
        ],
        data: [],
      },
    };
  },

  watch: {
    datatable: {
      handler() {
        this.$emit('datatable', this.datatable);
      },
      deep: true
    }
  },

  methods: {
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", "beritaacara/widget/waiting-approval");
      this.datatable.loading = true;
      this.$axios
        .post("beritaacara/widget/waiting-approval")
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.datatable.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getData(true);
  },
};
</script>
