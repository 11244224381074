<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-card-title>General Affair</v-card-title>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template v-slot:document_no_="{ item }" v-if="!isMobile">
          <detail-dialog :id="parseInt(item.id)" @callback="getData(true)">

            <template v-slot:activator="{ on }">
              <a href="javascript:;" v-on:click="on">{{ item.document_no_ }}</a>
            </template>

          </detail-dialog>
        </template>

        <template v-slot:status="{ item }">
          <app-document-status v-model="item.status"></app-document-status>
        </template>
        <template v-slot:substatus="{ item }">
          <app-document-status v-model="item.substatus"></app-document-status>
        </template>
        <template v-slot:created_by_username="{ item }">
          <app-user-info :username="item.created_by_username">{{ item.created_by_username }}</app-user-info>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
import DetailDialog from './DetailDialog.vue';
export default {
  components: { DetailDialog },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "mjo/data-ga",
        headers: [
          { text: "No.", value: "document_no_", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Source Document", value: "source_document", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap", cellClass: "text-no-wrap text-center" },
          { text: "Received", value: "received", class: "text-no-wrap", cellClass: "text-no-wrap text-center" },
          { text: "Sub Status", value: "substatus", class: "text-no-wrap", cellClass: "text-no-wrap text-center" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "FA Serial No.", value: "fa_serial_no_", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "FA Description", value: "fa_description", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Problem Description", value: "problem_description", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap", cellClass: "text-no-wrap" },
        ],
      },
    };
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
  },
  mounted() {
    this.filterData.area_code = this.$store.state.master.area;
  }
};
</script>
