<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-card-title>My List</v-card-title>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" show-filter :filter-data="filterForm">
        <template v-slot:document_no_="{ item }" v-if="!isMobile">
          <detail-dialog :id="parseInt(item.id)" @callback="getData(true)">

            <template v-slot:activator="{ on }">
              <a href="javascript:;" v-on:click="on">{{ item.document_no_ }}</a>
            </template>

          </detail-dialog>
        </template>

        <template v-slot:status="{ item }">
          <app-document-status v-model="item.status"></app-document-status>
        </template>
        <template v-slot:substatus="{ item }">
          <app-document-status v-model="item.substatus"></app-document-status>
        </template>
        <template v-slot:created_by_username="{ item }">
          <app-user-info :username="item.created_by_username">{{ item.created_by_username }}</app-user-info>
        </template>

        <template #filter-body>
          <v-autocomplete v-model="filterForm.status" label="Status" :items="filterData.status" class="mb-2" hide-details multiple deletable-chips chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.substatus" label="Sub Status" :items="filterData.substatus" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.area_code" label="Area" :items="filterData.area_code" item-text="area_code" item-value="area_code" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
import DetailDialog from './DetailDialog.vue';
export default {
  components: { DetailDialog },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "cpar/data",
        headers: [
          { text: "No.", value: "document_no_", class: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap" },
          { text: "Source Document", value: "source_document", class: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap" },
          { text: "Department", value: "department.dept_code", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap", cellClass: "text-center" },
          { text: "Sub Status", value: "substatus", class: "text-no-wrap", cellClass: "text-center" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap" },
        ],
      },
      filterData: {
        status: [
          'open',
          'waiting-approval',
          'approved',
          'cancel',
          'closed',
        ],
        substatus: [
          'on-process',
          'verified',
        ],
        area_code: [],
        department: [],
      },
      filterForm: {
        status: [],
        substatus: [],
        area_code: [],
        department: [],
      }
    };
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
  },
  mounted() {
    this.filterData.area_code = this.$store.state.master.area;
  }
};
</script>
