<template>
  <div>
    <v-dialog v-model="dialog" width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" icon  text small dark v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> New PIC <br>[{{ proc }}] </v-card-title>

        <v-card-text class="pt-2">
          <v-text-field label="Doc No." hide-details dense outlined class="mb-2" readonly v-model="cpar_header.document_no_" filled></v-text-field>
          <v-text-field label="Proc" hide-details dense outlined class="mb-2" readonly v-model="proc" filled></v-text-field>
          <v-autocomplete 
            label="User" 
            hide-details
            dense
            outlined
            hide-no-data
            hide-selected
            class="mb-2"
            v-model="user_id"
            :items="users"
            item-text="username" 
            item-value="id"
            placeholder="Start typing to Search"
            :search-input.sync="searchUser"></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="savePIC"> Save </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = !dialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    cpar_header: Object,
    proc: String,
  },

  data: () => {
    return {
      loading: false,
      dialog: false,
      user_id: 0,
      users: [],
      searchUser: "",
    };
  },
  watch:{
    searchUser: function(val){
      if (val)
      this.getUsers(true);
    },
    dialog(newVal){
      if(newVal == true){
        this.user_id = null;
        this.users = [];
        this.searchUser = "";
      }
    }
  },
  methods: {
    async getUsers(refresh = false) {
      this.loading = true;
      this.users = [];
      if (refresh == true) this.AxiosStorageRemove("GET", "cparpic/users");
      var config = {
        params: {
          cpar_header_id: this.cpar_header.id,
          proc: this.proc,
          search: this.searchUser
        },
        cacheConfig: !refresh,
      };
      await this.$axios
        .get("cparpic/users", config)
        .then((res) => {
          this.users = res.data.data.results;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    async savePIC() {
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("cpar_header_id", this.cpar_header.id);
      formData.append("proc", this.proc);
      formData.append("user_id", this.user_id);
      await this.$axios
        .post("cparpic/save", formData)
        .then((res) => {
          this.loading = false;
          this.dialog = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.$emit("callback");
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
  },
};
</script>
