<template>
  <div>
    <div class="no-print" style="margin-bottom: 60px;"></div>

    <div v-html="rephtml" class="mx-auto">
    </div>

    <div class="no-print" style="display:block; width: 100%;position: fixed;z-index: 99999;top: 0; left: 0;">
      <v-card elevation="1" flat>
        <v-card-text class="py-1 d-flex">
          <v-btn @click="goBack()" color="primary" text>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <h3 class="pt-1">{{ title }}</h3>
          <v-spacer></v-spacer>
          <v-btn @click="printAction()" color="primary" class="ml-2" text>Cetak</v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
@media print {

  body {
    margin: 0 !important;
  }

  .no-print {
    display: none !important;
  }
}
</style>

<script>
export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: "Print Document"
    },
    docUri: {
      type: String,
      default: ""
    },
  },
  data: () => ({
    rephtml: '',
  }),
  methods: {
    async getDetail() {
      if (this.docUri == "") return;

      // this.rephtml = null;

      this.loading = true;

      // this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.$route.query.id
        },
        cacheConfig: false,
      };
      await this.$axios
        .get(this.docUri, config)
        .then((res) => {
          this.loading = false;
          this.rephtml = res.data;
          // this.showLoadingOverlay(false);
        })
        .catch((error) => {
          this.loading = false;
          // this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });

    },
    printAction() {
      window.print();
    }
  },
  mounted() {
    this.getDetail();
  },
};
</script>
