var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","elevation":"0"}},[_c('v-card-title',[_vm._v("My List")]),_c('v-divider'),_c('app-data-table',{ref:"refAppDataTable",attrs:{"uri":_vm.datatable.uri,"headers":_vm.datatable.headers,"show-filter":"","filter-data":_vm.filterForm},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"document_no_",fn:function(ref){
var item = ref.item;
return [_c('detail-dialog',{attrs:{"id":parseInt(item.id)},on:{"callback":function($event){return _vm.getData(true)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":on}},[_vm._v(_vm._s(item.document_no_))])]}}],null,true)})]}}:null,{key:"status",fn:function(ref){
var item = ref.item;
return [_c('app-document-status',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"substatus",fn:function(ref){
var item = ref.item;
return [_c('app-document-status',{model:{value:(item.substatus),callback:function ($$v) {_vm.$set(item, "substatus", $$v)},expression:"item.substatus"}})]}},{key:"created_by_username",fn:function(ref){
var item = ref.item;
return [_c('app-user-info',{attrs:{"username":item.created_by_username}},[_vm._v(_vm._s(item.created_by_username))])]}},{key:"filter-body",fn:function(){return [_c('v-autocomplete',{staticClass:"mb-2",attrs:{"label":"Status","items":_vm.filterData.status,"hide-details":"","multiple":"","deletable-chips":"","chips":"","clear-icon":"","clearable":"","small-chips":"","hide-selected":"","outlined":""},model:{value:(_vm.filterForm.status),callback:function ($$v) {_vm.$set(_vm.filterForm, "status", $$v)},expression:"filterForm.status"}}),_c('v-autocomplete',{staticClass:"mb-2",attrs:{"label":"Sub Status","items":_vm.filterData.substatus,"hide-details":"","multiple":"","chips":"","deletable-chips":"","clear-icon":"","clearable":"","small-chips":"","hide-selected":"","outlined":""},model:{value:(_vm.filterForm.substatus),callback:function ($$v) {_vm.$set(_vm.filterForm, "substatus", $$v)},expression:"filterForm.substatus"}}),_c('v-autocomplete',{staticClass:"mb-2",attrs:{"label":"Area","items":_vm.filterData.area_code,"item-text":"area_code","item-value":"area_code","hide-details":"","multiple":"","chips":"","deletable-chips":"","clear-icon":"","clearable":"","small-chips":"","hide-selected":"","outlined":""},model:{value:(_vm.filterForm.area_code),callback:function ($$v) {_vm.$set(_vm.filterForm, "area_code", $$v)},expression:"filterForm.area_code"}})]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }