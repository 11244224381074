<template>
  <div>
    <v-card flat elevation="0" class="">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: null,
      datatable: {
        uri: 'customer/data',
        headers: [
          { text: "No.", value: "no_" },
          { text: "Customer Name", value: "name" },
          { text: "Address", value: "address" },
          { text: "City", value: "city" },
          { text: "Phone No.", value: "phone_no_" },
        ],
      },
    };
  },
  mounted() {
  },
};
</script>
