<template>
  <div>
    <v-card flat elevation="0" class="">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" :filter-data="datatable.filter" show-filter>
        <template #filter-body>
          <v-select outlined label="Sales Type" v-model="datatable.filter.sales_type" :items="sales_types"></v-select>
          <v-text-field outlined label="Item No." v-model="datatable.filter.item_no_"></v-text-field>
          <v-text-field outlined label="Unit Price" v-model="datatable.filter.unit_price"></v-text-field>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: null,
      datatable: {
        uri: 'salesprice/data',
        headers: [
          { text: "Sales Type", value: "sales_type" },
          { text: "Sales Code", value: "sales_code" },
          { text: "Item No.", value: "item_no_" },
          { text: "Item Description", value: "item_description" },
          { text: "UoM Code", value: "unit_of_measure_code" },
          { text: "Unit Price", value: "unit_price", cellClass: "text-end" },
          { text: "Starting Date", value: "starting_date" },
          { text: "Ending Date", value: "ending_date" },
        ],
        filter: {
          sales_type: null,
          item_no_: null,
          unit_price: null,
        }
      },

      sales_types: [null, 'Customer Price Group', 'Customer']
    };
  },
  mounted() {
  },
};
</script>
