<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-card-title>All Vendor</v-card-title>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" :filter-data="datatable.filter">
        <template v-slot:name="{ item }">
          <router-link :to="'/purchasing/vendor/detail?id=' + item.id">{{ item.name }}</router-link>
        </template>

        <template v-slot:status="{ item }">
          <app-document-status v-model="item.status"></app-document-status>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "vendor/data",
        headers: [
          { text: "Name", value: "name", class: "text-no-wrap" },
          { text: "Category", value: "category", class: "text-no-wrap" },
          { text: "Website", value: "website", class: "text-no-wrap" },
          { text: "E-Mail", value: "email", class: "text-no-wrap" },
          { text: "Phone No.", value: "phone_no", class: "text-no-wrap" },
          { text: "Address", value: "address", class: "text-no-wrap" },
        ],
        filter: {
          status: this.status
        }
      },
    };
  },
  watch: {
    status(val) {
      this.datatable.filter.status = val;
    }
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
  },
};
</script>
