<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <!-- <v-select label="Access Type" outlined dense hide-details v-model="accessType" :items="accessTypes" @change="getData(true)" style="max-width: 150px;"></v-select> -->
         <v-toolbar-title>Data Acess</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="redirect('Setting.General.DataAccessEdit')">Modify</v-btn>
      </v-toolbar>
    </v-card>
    <v-divider></v-divider>
    <v-card flat elevation="0">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" :filter-data="datatable.filter" show-filter>
        <template v-slot:department_id="{ item }">
          <span v-if="item.department_id != 0">{{ item.department_id }}</span>
        </template>
        <template v-slot:action="{ item }">
          <v-btn icon text x-small class="mx-1" color="primary" @click="deleteData(item)"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <template #filter-body>
          <v-select label="Access Type" outlined dense hide-details v-model="datatable.filter.access_type" :items="access_type_list"></v-select>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {},
  data() {
    return {
      datatable: {
        uri: "dataaccess/data",
        headers: [
          { text: "ID", value: "id", class: "text-no-wrap" },
          { text: "Username", value: "username", class: "text-no-wrap" },
          { text: "Access Type", value: "access_type", class: "text-no-wrap" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap" },
          // { text: "Dept ID", value: "department_id", class: "text-no-wrap" },
          // { text: "Dept Code", value: "dept_code", class: "text-no-wrap" },
          { text: "Dept Name", value: "dept_name", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "#", value: "action", name: "action", class: "text-no-wrap", searchable: false, sortable: false },
        ],
        filter: {
          access_type: ""
        }
      },

      access_type_list: ['all', 'area', 'department']
    };
  },

  methods: {
    refreshData() {
      this.refs.refAppDataTable.refresh();
    },
    modify() {
      this.redirect("Setting.General.DataAccessEdit");
    },
    async deleteData(item) {
      const post = async () => {
        this.loading = true;

        const uri = 'dataaccess/delete';
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios.post(uri, formData)
          .then((res) => {
            const resData = res.data;
            if (resData.status == 'success') {
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }

            this.loading = false;

            this.refreshData(true);
          })
          .catch((e) => {
            this.loading = false;

            this.refreshData(true);

            this.axiosErrorHandler(e);
          });
      }
      this.showConfirm('Confirm', 'Delete Access?', post);
    },
  },
};
</script>

<style scoped>
tr .show-on-hover button {
  display: none;
}

tr:hover .show-on-hover button {
  display: block;
}
</style>
