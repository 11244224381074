<template>
  <div>
    <v-container>
      <page-title :title="pageTitle"></page-title>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-tabs v-model="tab">
              <v-tab>List Data</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <list-data></list-data>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from './components/ListData.vue';

export default {
  components: {
    ListData
  },

  data() {
    return {
      pageTitle: 'Mail Outbox',
      tab: null,
    };
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission('mailoutbox', 'view', true)
  },
};
</script>